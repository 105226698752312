import React, { useRef } from "react"
import { Container, Row, Col, Button } from "react-bootstrap";
import root from "window-or-global";
import BackgroundImage from 'gatsby-background-image'



import subPageImg from "../../images/headers/subheader_blue.svg";


const Banner = (props) => {
  const {data, bgData, bgImg = subPageImg, className = "", subpage, customButtons, hideScrollButton} = props;
  // const { title = {}, subtitles = [], buttonData = {}, img, customButtons = [] } = data;
  const myRef = useRef(null);

  // const executeScroll = () => scrollToRef(myRef);
  const executeScroll = () => {
    if(root.scrollTo && myRef.current) {
      root.scrollTo({
        top: myRef.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }
  const titleGridSizes = {
    xs: 12,
    lg: 7,
  }
  return (
    <>
      <div className={`banner ${className} ${subpage ? "banner-subpage" : ""}`}>
        {(bgData) && (<BackgroundImage
          Tag="section"
          className={`banner-img-whole`}
          fluid={bgData.file.childImageSharp.fluid}
          style={{position: "absolute"}}
          />)}
        {(bgImg && !bgData) && (<div className={`banner-img-whole ${subpage ? "banner-subpage" : ""}`} style={{
          backgroundImage: `url(${bgImg})`,
          
        }} />)}
        {(data) && (<Container className="banner-container">
          <Row className={subpage && "align-items-center"}>
            <Col xs={12} lg={7}>
              <Container fluid className="no-gutters">
                <Row>
                  <Col {...data.titleGridSizes}>
                    <div className={`${data.title.className || ""} banner-title`}>
                      {data.title.name || ""}
                    </div>
                  </Col>
                </Row>
                {data.subtitles.map(({ name = "", className }) => {
                  return (
                    <Row key={name}>
                      <Col {...data.titleGridSizes}>
                        <div className={`banner-subtitle ${className ? className : ""}`}>
                          {name}
                        </div>
                      </Col>
                    </Row>
                  )
                })}
                <Row>
                  <Col xs={12}>
                    <Container fluid className="no-gutters-row-only">
                      <Row>
                        {!hideScrollButton && (<Col>
                          {data.buttonData.name && <Button variant={data.buttonData.colour || "green"} className="btn-banner" onClick={() => executeScroll()}>
                            <i className="fas fa-angle-down mr-2" />{data.buttonData.name || ""}
                          </Button>}
                        </Col>)}
                      {customButtons && (customButtons)}
                      </Row>

                    </Container>

                  </Col>
                  
                </Row>
              </Container>
            </Col>
            {(data.img) && (<Col className="banner-side-img-col">
              <img alt={data.imgAlt} className={`banner-side-img block-center img-fluid ${data.imgNoShadow ? "no-shadow" : ""}`} src={data.img} />
            </Col>)}
          </Row>
        </Container>)} 
        {props.children}
      </div>
      <div ref={myRef} />
    </>
  );
}

export default Banner;