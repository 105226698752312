import PropTypes from "prop-types"
import React, { useState, Fragment } from "react"
import { Link, graphql, useStaticQuery } from "gatsby";
import { Container, Navbar, Nav, NavDropdown, Row, Col } from "react-bootstrap";

import vostronetLogo from "../images/vostronet-logo-mono.svg";

import Img from "gatsby-image"

import { GetTopBlogs, GetTopBlogEntries } from "../utils/wp-async";
// import studentAccoImage from "../images/box-headers/studentaccom.jpg"
// import buildtorentImage from "../images/box-headers/buildtorent.jpg"
// import coworkingImage from "../images/box-headers/coworking.jpg"
// import hotelImage from "../images/box-headers/hotel.jpg"
// import publicwifiImage from "../images/box-headers/public_wifi.jpg"
// import resfibernetImage from "../images/box-headers/residential_mdu.jpg"

//245 / 53

const imageQuery = graphql`query {
  studentAccoImage: file(relativePath: { eq: "box-headers/studentaccom.jpg" }) {
    childImageSharp {
      fixed(width: 245, height: 132) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  buildtorentImage: file(relativePath: { eq: "box-headers/buildtorent.jpg" }) {
    childImageSharp {
      fixed(width: 245, height: 132) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  coworkingImage: file(relativePath: { eq: "box-headers/coworking.jpg" }) {
    childImageSharp {
      fixed(width: 245, height: 132) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  hotelImage: file(relativePath: { eq: "box-headers/hotel.jpg" }) {
    childImageSharp {
      fixed(width: 245, height: 132) {
        ...GatsbyImageSharpFixed
      }
    }
  }

  publicwifiImage: file(relativePath: { eq: "box-headers/public_wifi.jpg" }) {
    childImageSharp {
      fixed(width: 245, height: 132) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  resfibernetImage: file(relativePath: { eq: "box-headers/residential_mdu.jpg" }) {
    childImageSharp {
      fixed(width: 245, height: 132) {
        ...GatsbyImageSharpFixed
      }
    }
  }
}`



const Header = ({ siteTitle, title, blogData }) => {
  // const [result, loading, error] = GetTopBlogEntries();
  const images = useStaticQuery(imageQuery);
  const [mobileMenuToggle, setMobileMenuToggle] = useState(false);
  const [internetMenuHover, setInternetMenuHover] = useState(false);
  const [internetMenuToggle, setInternetMenuToggle] = useState(false);
  const [wpBlogs, setBlogs] = useState(null);
  const whiteThemePages = [
    "Smarter Internet",
    "Student Accommodation",
    "Internet Environments",
    "Build-to-Rent",
    "Hotel",
    "Public Wi-Fi",
    "Co-working",
    "About Us",
    "Our Network",
    "Contact Us",
    "Residential Fibre Network",
    "Software Platform",
    "404",
  ];

  const whiteTheme = whiteThemePages.includes(title);
  const {
    studentAccoImage,
    buildtorentImage,
    coworkingImage,
    hotelImage,
    publicwifiImage,
    resfibernetImage,
  } = images;
  const internetEnvironmentMenu = [
    {
      title: "Student Accommodation",
      desc: "Meet students' demand",
      link: "/student-accommodation/",
      details: "Student Accommodation: The importance of staying modern",
      subDetails: "We are experts at delivering lightning fast internet to 25,000+ connections in student accommodation",
      img: studentAccoImage,
      key: "student",
    },
    {
      title: "Residential MDU",
      desc: "Smarter home network",
      link: "/residential-fibre-network/",
      details: "A one-stop precinct; the rise in popularity for mixed-use developments in Australia",
      subDetails: "For developers, it is an opportunity to create communities throughout these spaces, enticing residents to the development and ultimately lowering the risk of low sales purchases.",
      img: resfibernetImage,
      key: "residential",
    },
    {
      title: "Build-to-Rent",
      desc: "Simplify Internet Delivery",
      link: "/build-to-rent/",
      details: "Build-to-rent, the future of affordable living",
      subDetails: "BTR offers an affordable rental solution by increasing the rental supply in the property market quickly as it doesn't need to be pre-sold off-the-plan, standardising rental costs and encourage developers/owners to lower costs by providing them with better yield returns.",
      img: buildtorentImage,
      key: "btr",
    },
    {
      title: "Co-Working",
      desc: "Software-defined collaboration",
      link: "/co-working/",
      details: "Modern Workplaces need a Modern Network",
      subDetails: "Gone are the days of office walls, personal cubicles and the kilometres of cabling between them. And such is the case for co-working environments.",
      img: coworkingImage,
      key: "coworking",
    },
    {
      title: "Hotels",
      desc: "Make Wi-Fi an asset",
      link: "/hotel/",
      details: "VostroNet’s Advanced Software Redefines Hotel Wi-Fi Management",
      subDetails: "VostroNet’s advanced Q2 software platform provides management with visibility over its user base and provides the tools to easily administer users on the network. The platform also generates sophisticated analytics on user behaviour to aid management in optimising its offering for its users.",
      img: hotelImage,
      key: "hotel",
    },
    {
      title: "Public Wi-Fi",
      desc: "Get online everywhere",
      link: "/public-wifi/",
      details: "Public Wi-Fi as a driver of increased sales",
      subDetails: "There’s nothing more appealing for a customer then connecting to a public network when they’re on the go. Free public internet means the customer is off their mobile data, connected to Wi-Fi throughout their stay in that public area; they stay for longer, embrace the environment and are satisfied during their visit.",
      img: publicwifiImage,
      key: "publicWifi",
    },
  ];

  return (
    <header className={`navigation ${whiteTheme ? "nav-white" : ""} ${mobileMenuToggle ? "mobile-menu-active" : ""}`}>
      <Container className="navigation-container no-gutters">
        <Navbar expand="xl" className="nav-desktop">
          <Navbar.Brand href="/">
            <img src={vostronetLogo} className="nav-logo" alt="vostronet_logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="mr-auto basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <div className="dropdown nav-item">
                <a
                  href="/internet-environments/"
                  onMouseEnter={() => {
                    setInternetMenuHover(true);
                  }}
                  onMouseLeave={() => {
                    setInternetMenuHover(false);
                  }}
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="basic-nav-dropdown"
                  className={`dropdown-toggle nav-link ${title === "Internet Environments" ? "font-darkblue5" : "font-black"}`}
                  role="button">
                  Internet Environments <i className={`fas fa-angle-${internetMenuHover ? "up" : "down"} ml-1`} />
                </a>
                <IEMenu internetEnvironmentMenu={internetEnvironmentMenu} wpBlogs={wpBlogs} setBlogs={(data) => setBlogs(data)} />
              </div>

              <Nav.Link
                href="/data-network-and-enterprise/"
                className={`mx-2 ${title === "Data Network and Enterprise" ? "font-darkblue5" : "font-black"}`}
              >
                Connectivity Solutions
              </Nav.Link>
              <Nav.Link href="/software-platform/" className={`${title === "Software Platform" ? "font-darkblue5" : "font-black"}`}>Software Platform</Nav.Link>
              <Nav.Link
                href="/about-us/"
                className={`mx-2 ${title === "About Us" ? "font-darkblue5" : "font-black"}`}
              >
                Why VostroNet
              </Nav.Link>
              <Nav.Link href="https://help.vostro.cloud/" className="mx-2 font-black" rel="noopener" target="_blank">{"Support"}</Nav.Link>
            </Nav>
            <Nav className="ml-auto">
              <Nav.Link className="link-contact btn btn-long mx-2 align-items-center" href="/contact-us/">{"Contact Us"}</Nav.Link>
              <Nav.Link href="http://wifi.vostro.live/" className="btn-login btn mx-2 align-items-center" rel="noopener" target="_blank">
                <i className="fas fa-user mr-2" />{"Login"}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Navbar expand="xl" className={`nav-mobile ${mobileMenuToggle ? "active" : ""}`}>
          <Navbar.Brand href="/">
            <img src={vostronetLogo} className="nav-logo" alt="vostronet_logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setMobileMenuToggle(!mobileMenuToggle)}>
            <div className="burger-group">
              <span className="burger-line line-1" />
              <span className="burger-line line-2" />
              <span className="burger-line line-3" />
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav justify className={` ${!internetMenuToggle ? "menushow-left" : "menuhide-right"} width-100`}>
              <NavDropdown.Divider />
              <Nav.Link onClick={() => setInternetMenuToggle(!internetMenuToggle)}>
                <div className="d-inline-block">
                  Internet Environments
                </div>
                <i className="float-right mr-3 mt-1 d-inline-block fas fa-angle-right" />
              </Nav.Link>
              <NavDropdown.Divider />
              <Nav.Link href="/data-network-and-enterprise/">Connectivity Solutions</Nav.Link>
              <NavDropdown.Divider />
              <Nav.Link href="/software-platform/">Software Platform</Nav.Link>
              <NavDropdown.Divider />
              <Nav.Link href="/about-us/">Why VostroNet</Nav.Link>
              <NavDropdown.Divider />
              <Nav.Link href="https://help.vostro.cloud/" target="_blank">{"Support"}</Nav.Link>
              <Row>
                <Col>
                  <a className="link-contact btn my-3 mx-auto" href="/contact-us/">
                    <div className="mx-auto">
                      {"Contact Us"}
                    </div>
                  </a>
                </Col>
                <Col>
                  <a href="http://wifi.vostro.live/" className="btn-login btn mx-auto my-3" rel="noopener" target="_blank">
                    <div className="block-center">
                      <i className="fas fa-user mr-2" />{"Login"}
                    </div>
                  </a>
                </Col>
              </Row>
            </Nav>

            <Nav className={`internet-mobile-menu ${internetMenuToggle ? "menushow-right" : "menuhide-left"}`}>
              <NavDropdown.Divider />
              <Nav.Link className="mobile-internet-menu" onClick={() => setInternetMenuToggle(!internetMenuToggle)}>
                <i className="mr-3 fas fa-angle-left font-darkblue" />
                <div className="d-inline-block font-black mobile-internet-menu">
                  Internet Environments
                </div>
              </Nav.Link>
              <NavDropdown.Divider />
              <div className="menu-section mx-2">
                {
                  internetEnvironmentMenu.map((menuData) => {
                    const { title, desc, link } = menuData;
                    return (
                      <Link to={link} key={title} className="nav-sub-menu-item">
                        {/* <i className="fal fa-angle-right mx-2" /> */}
                        <div className="sub-menu">
                          <div className="title">{title}</div>
                          <div className="desc">{desc}</div>
                        </div>
                      </Link>
                    );
                  })
                }
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
}

class IEMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInternetEnvironment: null,
      wpBlogs: props.wpBlogs || null,
    }
  }

  async componentDidMount() {
    if (!this.state.wpBlogs) {
      const res = await GetTopBlogs();
      // const res = null;
      console.log({ res })
      return this.setState({ wpBlogs: res })
    }
  }

  createMarkup(string) {
    return { __html: string };
  }

  render() {
    const { internetEnvironmentMenu, wpBlogs, setBlogs } = this.props;
    console.log({ props: this.props })
    return (
      <div className={`dropdown-menu m-0 dropdown-container ${!!this.state.selectedInternetEnvironment ? "selected" : ""}`} aria-labelledby="basic-nav-dropdown">
        <div className="menu-section">
          {
            internetEnvironmentMenu.map((menuData) => {
              const { title, desc, link } = menuData;
              return (
                <Link to={link} className="nav-sub-menu-item" key={title} onMouseEnter={() => this.setState({ selectedInternetEnvironment: menuData })}>
                  <i className="fal fa-angle-right mx-2" />
                  <div className="sub-menu">
                    <div className="title">{title}</div>
                    <div className="desc">{desc}</div>
                  </div>
                </Link>
              );
            })
          }
        </div>
        {this.state.selectedInternetEnvironment && (<Link to={this.state.selectedInternetEnvironment.link || "#"} className={`detail-section`}>
          <Img
            fixed={this.state.selectedInternetEnvironment.img.childImageSharp.fixed}
            alt={this.state.selectedInternetEnvironment.title || ""} className="detail-img mb-3" />
          {this.state.wpBlogs ? (
            <Fragment>
              <div className="detail-title mb-2 font-grey" dangerouslySetInnerHTML={this.createMarkup(((this.state.wpBlogs[this.state.selectedInternetEnvironment.key] || {}).title || {}).rendered || "")} />
              <div className="detail-desc mb-3 font-grey" dangerouslySetInnerHTML={this.createMarkup(((this.state.wpBlogs[this.state.selectedInternetEnvironment.key] || {}).excerpt || {}).rendered || "")} />
              <button className="btn btn-green btn-small btn-low">
                {"Explore"}
              </button>
            </Fragment>
          ) : (
              <div className="loader mx-auto mt-4" />
            )}
        </Link>)}
      </div>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
