import { useEffect, useState } from "react";
import wpapi from "wpapi";
import {getCategoryId} from "./categories";

const blogUrl = "https://blog.vostronet.com";
const wp = new wpapi({ endpoint: `${blogUrl}/wp-json` });

export function useAsyncWP(total = 3, category = null) {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchWP() {
      try {
        const res = await wp.posts().categories(category).embed().perPage(total);
        if (!!res.errors) {
          await setError(res.errors);
        }
        await setResult(res);
        await setLoading(false);
      } catch (error) {
        await setLoading(false);
        await setError(error);
      }
    }

    if (!result) {
      fetchWP();
    }
  });

  return [result, loading, error];
}

export async function GetTopBlogs() {
  const studentCategory = getCategoryId("Student Accommodation");
  const residentialCategory = getCategoryId("Residential Development");
  const btrCategory = getCategoryId("Build-to-Rent");
  const coWorkingtCategory = getCategoryId("Co-Working Space");
  const hotelCategory = getCategoryId("Hotels");
  const publicWifiCategory = getCategoryId("Public Wi-Fi");

  const student = await wp.posts().categories(studentCategory).perPage(1);
  const residential = await wp.posts().categories(residentialCategory).perPage(1);
  const btr = await wp.posts().categories(btrCategory).perPage(1);
  const coworking = await wp.posts().categories(coWorkingtCategory).perPage(1);
  const hotel = await wp.posts().categories(hotelCategory).perPage(1);
  const publicWifi = await wp.posts().categories(publicWifiCategory).perPage(1);
  console.log({missing: coworking})
  const res = {
    student: student[0],
    residential: residential[0],
    btr: btr[0],
    coworking: coworking[0],
    hotel: hotel[0],
    publicWifi: publicWifi[0],
  };

  return res;
}

export function GetTopBlogEntries() {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchWP() {
      try {
        const studentCategory = getCategoryId("Student Accommodation");
        const residentialCategory = getCategoryId("Residential Development");
        const btrCategory = getCategoryId("Build-to-Rent");
        const coWorkingtCategory = getCategoryId("Co-Working Space");
        const hotelCategory = getCategoryId("Hotels");
        const publicWifiCategory = getCategoryId("Public Wi-Fi");

        const student = await wp.posts().categories(studentCategory).perPage(1);
        const residential = await wp.posts().categories(residentialCategory).perPage(1);
        const btr = await wp.posts().categories(btrCategory).perPage(1);
        const coworking = await wp.posts().categories(coWorkingtCategory).perPage(1);
        const hotel = await wp.posts().categories(hotelCategory).perPage(1);
        const publicWifi = await wp.posts().categories(publicWifiCategory).perPage(1);
        const res = {
          student: student[0],
          residential: residential[0],
          btr: btr[0],
          coworking: coworking[0],
          hotel: hotel[0],
          publicWifi: publicWifi[0],
        };
        await setResult(res);
        await setLoading(false);
      } catch (error) {
        await setLoading(false);
        await setError(error);
      }
    }

    if (!result) {
      fetchWP();
    }
  });

  return [result, loading, error];
}
