import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import vostronet_logo from "../images/vostronet-logo-mono.svg";

import fb_icon from "../images/footer/fb_icon.svg";
import twitter_icon from "../images/footer/twitter_icon.svg";
import instagram_icon from "../images/footer/instagram_icon.svg";
import linkedin_icon from "../images/footer/linkedin_icon.svg";

const Footer = () => {

  return (
    <div className="bg-grey footer">
      <Container className="py-5">
        <Row className="button-row">
          <Col xs={12} lg={4}>
            <Link to="/our-network/" className="btn btn-footer block-center mb-3">
              {"Our Network"}
            </Link>
          </Col>
          <Col xs={12} lg={4}>
            <Link to="/software-platform/" className="btn btn-footer block-center mb-3">
              {"View our Platform"}
            </Link>
          </Col>
          <Col xs={12} lg={4}>
            <Link to="/contact-us/" className="btn btn-footer block-center mb-3">
              {"Get Started"}
            </Link>
          </Col>
        </Row>
        <Row className="py-5">
          <Col xs={12} lg={3} className="d-none d-lg-block">
            <Link to="/">
              <img src={vostronet_logo} alt="vostronet logo" className="logo block-center" />
            </Link>
            <Row className="social-icon-group my-4">
              <Col xs={2} className="ml-auto mr-1">
                <a href="https://www.facebook.com/vostronet.anz" rel="noopener" target="_blank">
                  <img src={fb_icon} alt="Facebook" />
                </a>
              </Col>
              <Col xs={2} className="mx-1">
                <a href="https://www.linkedin.com/company/vostronet/" rel="noopener" target="_blank">
                  <img src={linkedin_icon} alt="LinkedIn" />
                </a>
              </Col>
              <Col xs={2} className="mx-1">
                <a href="https://twitter.com/VostroNet" rel="noopener" target="_blank">
                  <img src={twitter_icon} alt="Twitter" />
                </a>
              </Col>
              <Col xs={2} className="mr-auto ml-1">
                <a href="https://www.instagram.com/vostronet/" rel="noopener" target="_blank">
                  <img src={instagram_icon} alt="Instagram" />
                </a>
              </Col>
            </Row>
{/*<Row>
              <Col xs={12}>
                <div style={{textAlign: "center"}}><iframe src="https://status.vostro.cloud/embed-status/light-lg" width="245" height="61" frameborder="0" scrolling="no" style={{border: "none"}}></iframe></div>
              </Col>
</Row>*/}
          </Col>


          <Col xs={12} lg={9}>
            <Row>
              <Col xs={12} lg>
                <Link to="/about-us/" className="title-link mb-3">
                  <div>{"Company"}</div>
                </Link>
                <Link to="/about-us/" className="sub-link">{"About"}</Link>
                <Link to="/our-network/" className="sub-link">{"Our Network"}</Link>
                <Link to="/contact-us/" className="sub-link">{"Contact Details"}</Link>
                <a href="https://blog.vostronet.com/" className="sub-link" rel="noopener" target="_blank">{"Blog"}</a>
              </Col>
              <Col xs={12} lg>
                <Link to="/internet-environments/" className="title-link mb-3">
                  <div>{"Internet"}</div>
                  <div>{"Environments"}</div>
                </Link>

                <Link to="/student-accommodation/" className="sub-link">{"Student Wi-Fi"}</Link>
                <Link to="/residential-fibre-network/" className="sub-link">{"Residential MDU"}</Link>
                <Link to="/build-to-rent/" className="sub-link">{"Build-to-Rent"}</Link>
                <Link to="/hotel/" className="sub-link">{"Hotels"}</Link>
                <Link to="/public-wifi/" className="sub-link">{"Public Wi-Fi"}</Link>
                <Link to="/co-working/" className="sub-link">{"Co-Working"}</Link>
              </Col>
              <Col xs={12} lg>
                <Link to="/software-platform/" className="title-link mb-3">
                  <div>{"Software"}</div>
                  <div>{"Platform"}</div>
                </Link>

                <Link to="/data-network-and-enterprise/" className="title-link mb-3">
                  <div>{"Connectivity"}</div>
                  <div>{"Solutions"}</div>
                </Link>

                <a href="/data-network-and-enterprise?tab=dark-fibre" className="sub-link">{"Dark Fibre"}</a>
                <a href="/data-network-and-enterprise?tab=ethernet-service" className="sub-link">{"Ethernet Services"}</a>
                <a href="/data-network-and-enterprise?tab=ip-transit" className="sub-link">{"IP Transit"}</a>
              </Col>
              <Col xs={12} lg={4}>
                <div className="title-link mb-3">
                  {"Resources"}
                </div>

                <a href="https://vost.ro/vprvpol" className="sub-link" rel="noopener" target="_blank">{"Privacy Policy"}</a>
                <a href="https://vost.ro/vgtcs" className="sub-link" rel="noopener" target="_blank">{"General Terms & Conditions"}</a>
                <a href="https://vost.ro/vcis" className="sub-link" rel="noopener" target="_blank">{"Critical Information Summary"}</a>
                <a href="https://wifi.vostro.live/" className="sub-link" rel="noopener" target="_blank">{"Portal & User Login"}</a>
                <a href="https://help.vostro.cloud/" className="sub-link" rel="noopener" target="_blank">{"Help Centre"}</a>
                <a href="https://status.vostro.cloud/" className="sub-link" rel="noopener" target="_blank">{"Status"}</a>
              </Col>

              <Col xs={12} lg={3} className="d-block d-lg-none">
                <div className="title-link mt-3">
                  {"Follow Us"}
                </div>
                <div className="social-icon-group my-4">
                  <a href="https://www.facebook.com/vostronet.anz" rel="noopener" target="_blank">
                    <img src={fb_icon} alt="Facebook" />
                  </a>
                  <a href="https://www.linkedin.com/company/vostronet/" rel="noopener" target="_blank">
                    <img src={linkedin_icon} alt="LinkedIn" />
                  </a>
                  <a href="https://twitter.com/VostroNet" rel="noopener" target="_blank">
                    <img src={twitter_icon} alt="Twitter" />
                  </a>
                  <a href="https://www.instagram.com/vostronet/" rel="noopener" target="_blank">
                    <img src={instagram_icon} alt="Instagram" />
                  </a>
                </div>
                <Link to="/">
                  <img src={vostronet_logo} alt="vostronet logo" className="logo block-center my-5" />
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;