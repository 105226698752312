const categories = [
  {
    id: 2,
    name: "Build-to-Rent",
  },
  {
    id: 137,
    name: "Co-living",
  },
  {
    id: 3,
    name: "Co-Working Space",
  },
  {
    id: 4,
    name: "Hotels",
  },
  {
    id: 5,
    name: "News",
  },
  {
    id: 6,
    name: "Public Wi-Fi",
  },
  {
    id: 7,
    name: "Residential Development",
  },
  {
    id: 8,
    name: "Retirement Living",
  },
  {
    id: 9,
    name: "Student Accommodation",
  },
  {
    id: 10,
    name: "Technical",
  },
];

export function getCategoryId(string) {
  const res = categories.find(category => category.name === string);
  return Number(res.id);
}



export default categories;